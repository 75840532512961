import * as React from "react";
import Paper from "@mui/material/Paper";
import "../admin/css/style.css";
import {
  MDBBtn,
  MDBCardBody,
  MDBCardText,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import request from "superagent";
import Swal from "sweetalert2";
import { Toast } from "../errorNotifier";
import { loader } from "../LoadingSpinner";
import { MuiFileInput } from "mui-file-input";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";

export default function AdmissionTab() {
  const navigate = useNavigate();
  const [admissionSms, setAdmissionSms] = useState("");
  const [admissionEmail, setAdmissionEmail] = useState("");
  const [listOfAdmittedStudents, setListOfAdmittedStudents] = useState([]);
  const [file, setFile] = useState(null);
  const [courseRegfile, setCourseRegFile] = useState(null);

  const [rows, setRows] = useState([]);

  const handleFileChange = (e) => {
    setFile(e);
  };

  const handleCourseRegFileChange = (e) => {
    setCourseRegFile(e);
  };

  const handleSubmit = async (e) => {
    if (!file) {
      Swal.fire({
        title: "Error!",
        text: "No file selected",
        icon: "error",
      });
      return;
    }

    loader({
      title: "Uploading",
      text: "Please wait...",
    });

    const formData = new FormData();
    formData.append("csv", file); // Ensure the name 'csv' matches your PHP script

    try {
      const res = await request
        .post("https://api.mcchstfuntua.edu.ng/admin/admission_upload.php")
        .send(formData)
        .set("Accept", "application/json");

      Toast.fire({
        icon: "success",
        title: "Uploaded successfully",
      });
      setFile(null);
    } catch (err) {
      console.error("Error uploading file", err);
      Swal.fire({
        title: "Error!",
        text: "There was an error uploading the file.",
        icon: "error",
      });
    }
  };

  const handleCourseRegUpload = async (e) => {
    if (!courseRegfile) {
      Swal.fire({
        title: "Error!",
        text: "No file selected",
        icon: "error",
      });
      return;
    }

    loader({
      title: "Uploading",
      text: "Please wait...",
    });

    const formData = new FormData();
    formData.append("csv", courseRegfile); // Ensure 'csv' matches your PHP script

    try {
      const res = await request
        .post("https://api.mcchstfuntua.edu.ng/admin/course_reg_upload.php")
        .send(formData)
        .set("Accept", "application/json");

      Toast.fire({
        icon: "success",
        title: "Uploaded successfully",
      });
      // setCourseRegFile(null);
    } catch (err) {
      console.error("Error uploading file", err);

      let errorMessage = "There was an error uploading the file.";
      if (err.response && err.response.body && err.response.body.message) {
        errorMessage = err.response.body.message; // Custom server error message if available
      }

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const handleSaveNotification = async () => {
    if (navigator.onLine) {
      // progress spinner
      loader({
        title: "Updating",
        text: "Please! wait.",
      });

      const data = {
        SmsNotification: admissionSms,
        EmailNotification: admissionEmail,
      };

      await request
        .post("https://api.mcchstfuntua.edu.ng/admin/save_admission_notifs.php")
        .type("application/json")
        .send(data)
        .then((response) => {
          const message = response.text;
          console.log(message);
          Toast.fire({
            icon: "success",
            title: "updated successfully",
          });
        })
        .catch((err) => {
          let errorText = err.response.text;
          console.log(errorText);

          Swal.fire({
            title: "Error!",
            text: "There is occoured an error",
            icon: "error",
          });
        });
    } else {
      Toast.fire({
        icon: "error",
        title: "No internet connection",
      });
    }
  };

  useEffect(() => {
    handleFetchData();
    handleGetListForAdmission();
  }, []);

  const handleFetchData = async () => {
    if (listOfAdmittedStudents.length > 0) return; // Prevents unnecessary fetches

    try {
      await request
        .get("https://api.mcchstfuntua.edu.ng/admin/get_admitted.php")
        .type("application/json")
        .then((response) => {
          // console.log("FETCH RES: ", response);
          setListOfAdmittedStudents(response.body.list);
          setAdmissionSms(response.body.smsNote);
          setAdmissionEmail(response.body.emailNote);
        });
    } catch (err) {
      // console.log(err);
    }
  };

  const handleDataBackup = async () => {
    loader({
      title: "Processing",
      text: "Please wait...",
    });

    try {
      const response = await request
        .get("https://api.mcchstfuntua.edu.ng/admin/admission/backup/")
        .responseType("blob") // Ensures the response is treated as a blob
        .then((response) => {
          console.log("FETCH RES: ", response);

          // Create a download link for the CSV file
          const url = window.URL.createObjectURL(new Blob([response.body]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "admission_data.csv"); // Define file name

          // Append the link to the body temporarily and click it to download
          document.body.appendChild(link);
          link.click();

          // Clean up by removing the link and revoking the URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          // Show success notification
          Toast.fire({
            icon: "success",
            title: "Backed successfully",
          });
        });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: err.message || "An error occurred",
      });
    }
  };

  const handleGetListForAdmission = async () => {
    try {
      const response = await request
        .get(
          "https://api.mcchstfuntua.edu.ng/admin/admission/admit/list_applicants/index.php"
        )
        .then((response) => {
          setRows(response.body);
          console.log("FETCH RES: ", response.body);
        });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: err.message || "An error occurred",
      });
    }
  };

  return (
    <div className="m-4 d-flex flex-column align-items-center">
      <MDBCardBody>
        <MDBCardText>
          <h4>Admission Management</h4>
        </MDBCardText>
      </MDBCardBody>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Admission Upload
          </div>
          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <MuiFileInput
                InputProps={{
                  inputProps: {
                    accept: ".csv",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                value={file}
                placeholder="choose the file here..."
                onChange={(e) => {
                  handleFileChange(e);
                }}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
              />

              <MDBBtn
                onClick={handleSubmit}
                style={{ background: "#05321e", height: "56px" }}
                className="p-3"
              >
                Upload
              </MDBBtn>

              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  fontStyle: "italic",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDataBackup();
                }}
              >
                backup admission data
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow>
          <MDBCol>
            <div className="p-2">
              <FormControl className="m-2" fullWidth>
                <InputLabel>Mode of Entry</InputLabel>
                <Select
                  // value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    // setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">Fresh</MenuItem>
                  <MenuItem value="Close">Retrainee</MenuItem>
                  <MenuItem value="">Direct entry</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="m-2" fullWidth>
                <InputLabel>Level of Entry</InputLabel>
                <Select
                  // value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    // setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">100 Level</MenuItem>
                  <MenuItem value="Close">200 Level</MenuItem>
                  <MenuItem value="Close">200 Level</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="m-2" fullWidth>
                <InputLabel>Duration</InputLabel>
                <Select
                  // value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    // setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">1 Year</MenuItem>
                  <MenuItem value="Close">2 Year</MenuItem>
                  <MenuItem value="Close">3 Year</MenuItem>
                </Select>
              </FormControl>

              <FormControl className="m-2" fullWidth>
                <InputLabel>Department</InputLabel>
                <Select
                  // value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    // setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Close">Close</MenuItem>
                </Select>
              </FormControl>
              <FormControl className="m-2" fullWidth>
                <InputLabel>Programme</InputLabel>
                <Select
                  // value={eventStatus}
                  label="Choose Programme"
                  onChange={(e) => {
                    // setEventStatus(e.target.value);
                  }}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Close">Close</MenuItem>
                </Select>
              </FormControl>

              <MDBBtn
                className="w-50"
                // onClick={handleCBTDownloadExcel}
                style={{ background: "#05321e" }}
              >
                Admit
              </MDBBtn>
            </div>
          </MDBCol>
          <MDBCol>
            <div
              className="mt-3"
              style={{ height: "480PX", overflow: "scroll" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Application Number</th>
                    <th>Fullname</th>
                    <th>Test Score</th>
                    <th>Select</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.ApplicationId}</td>
                        <td>{item.FirstName + " " + item.Surname}</td>
                        <td>
                          <MDBInput
                            className="center-cmp w-100"
                            // value={item.RegistrationFees}
                            onChange={(e) => {
                              // const newValue = e.target.value;
                              // const updatedRows = [...rows];
                              // updatedRows[index].RegistrationFees = newValue;
                              // setRows(updatedRows);
                            }}
                            // icon={nairaIcon}
                          />
                        </td>

                        <td>
                          <Checkbox checked="false" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Course Registration Upload
          </div>
          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <MuiFileInput
                InputProps={{
                  inputProps: {
                    accept: ".csv",
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                value={courseRegfile}
                placeholder="choose the file here..."
                onChange={handleCourseRegFileChange}
                clearIconButtonProps={{
                  title: "Remove",
                  children: <CloseIcon fontSize="small" />,
                }}
              />

              <MDBBtn
                onClick={handleCourseRegUpload}
                style={{ background: "#05321e", height: "56px" }}
                className="p-3"
              >
                Upload
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>

      <Paper className="mt-2" sx={{ width: "100%", overflow: "hidden" }}>
        <MDBRow style={{ padding: "10px" }}>
          <div style={{ fontWeight: "900", padding: "20px" }}>
            Notification of Admission
          </div>

          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <TextField
              value={admissionSms}
              className="center-cmp w-100"
              variant="outlined"
              multiline
              onChange={(e) => {
                setAdmissionSms(e.target.value);
              }}
              label="SMS Notification"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBCol>

          <MDBCol style={{ display: "flex", justifyContent: "center" }}>
            <div></div>
            <TextField
              value={admissionEmail}
              className="center-cmp w-100"
              variant="outlined"
              multiline
              onChange={(e) => {
                setAdmissionEmail(e.target.value);
              }}
              label="Email Notification"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <div
              onClick={handleSaveNotification}
              className="cus-button w-25 my-2"
            >
              Update
            </div>
          </MDBCol>
        </MDBRow>
      </Paper>
    </div>
  );
}
