import React from "react";

const WelcomeNoteComponent = () => {
  return (
    <div style={{ textAlign: "justify", padding: "20px", color: "#f3f1f1" }}>
      <h2 style={{ textAlign: "center" }}>
        Welcome to Muslim Community College of Health Sciences and Technology
        Funtua
      </h2>
      <p>
        We are delighted to have you visit our online home! At Muslim Community
        College of Health Sciences and Technology Funtua, we strive for academic
        excellence and are committed to contributing to national development
        through training, research, and manpower development in the healthcare
        sector.
      </p>
      <p>
        Explore our programs, learn about our vision and mission, and discover
        how we aim to make a positive impact on primary and secondary healthcare
        systems. Feel free to contact us if you have any inquiries or need more
        information.
      </p>
      <p>
        Thank you for visiting, and we look forward to sharing our journey of
        education, healthcare, and community service with you.
      </p>
    </div>
  );
};

export default WelcomeNoteComponent;
